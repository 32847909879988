<template>
  <div class="pa-0 ma-0">
    <div>
      <div class="py-4 px-10">
        <v-row no-gutters>
          <v-col align="left" class="my-auto" cols="6">
            <v-sheet>
              <h3 class="text--black" style="font-size: 15px; font-weight: 500">
                Tanggal
              </h3>
            </v-sheet>
          </v-col>
          <v-col align="left" class="my-auto" cols="6">
            <v-sheet>
              <h3 class="text--black" style="font-size: 15px; font-weight: 500">
                Document
              </h3>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>

      <!-- Jika documents kosong -->
      <v-snackbar v-model="snackbar" :timeout="3000" shaped top color="white">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <div
          v-if="documents.length === 0"
          class="d-flex justify-center align-center"
          style="height: 60vh"
      >
        <h3 class="text--grey" style="font-size: 14px; font-weight: 400">
          Tidak ada dokumen tersedia.
        </h3>
      </div>

      <!-- Jika documents tidak kosong -->
      <div v-else class="px-10">
        <template v-for="(item, index) in documents">
          <v-row no-gutters :key="index" class="py-5">
            <v-col align="left" class="my-auto" cols="6">
              <v-sheet>
                <div class="d-flex align-center">
                  <v-icon class="mr-4" style="color: #004643"
                  >mdi-clock-outline
                  </v-icon
                  >
                  <h3
                      class="text--black"
                      style="font-size: 14px; font-weight: 400"
                  >
                    {{ item.record_date }}
                  </h3>
                </div>
              </v-sheet>
            </v-col>
            <v-col align="left" class="my-auto" cols="6">
              <v-sheet>
                <div class="d-flex flex-column">
                  <a
                      v-for="file in item.files"
                      :key="file.id"
                      class="text-decoration-none"
                      style="color: #497bdd; font-size: 14px; font-weight: 400"
                      @click="onDownloadFile(file.id, file.file_name)"
                  >
                    {{ file.file_name }}
                  </a>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <v-divider :key="`divider-${index}`"></v-divider>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import mockFile from "@/mock/mockFile.js";

export default {
  props: {
    start_date: {
      type: String,
      required: true,
    },
    end_date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documents: [],
      snackbar: false,
      message: "",
    };
  },
  computed: {
    formattedGate() {
      return this.selectedGate.join(",");
    },
    fetchParams() {
      return {
        from: this.start_date,
        to: this.end_date,
      };
    },
  },
  watch: {
    fetchParams: {
      handler() {
        if (this.start_date && this.end_date) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.$axios.get("document-histories", {
          params: this.fetchParams,
        });
        // this.documents = mockFile.history.documents;
        this.documents = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async onDownloadFile(id, name) {
      try {
        const response = await this.$axios.get(`download-file-histories/${id}`);
        console.log(response)

        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data.pdfData;

        const url = mediaType + base64Data;
        const filename = name.split(".")[0] + ".pdf"

        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = error.response.data.message;
        this.snackbar = true;
      }
    }
  },
};
</script>
