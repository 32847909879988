<template>
  <div>
    <v-alert
        v-if="this.checkedCtp != null && this.checkedCtp.data.is_need_upload && this.alertCheckedCtp"
        v-model="alert"
        color="#F5D0D0"
        closable
    >
      <v-row no-gutters align="top">
        <v-col cols="12" md="8">
          <div class="text-body-2 font-weight-bold" style="font-family: Lexend,serif !important;">⚠️ Terdapat
            data yang kosong pada beberapa gerbang tol.
          </div>
          <div class="text-caption" style="font-family: Lexend,serif !important;">Silakan hubungi pihak MIY atau
            upload data ATT6 CTP untuk melengkapi data.
          </div>
        </v-col>
        <v-col align="right" cols="12" md="4">
          <v-icon :size="12" @click="alertCheckedCtp = false">mdi-close</v-icon>
          <div class="text-caption font-weight-bold mt-1"
               style="color: #486AF2; cursor: pointer;font-family: Lexend,serif !important;"
               @click="alertDialog = true">Lihat Gerbang
            Tidak Tersedia
          </div>
        </v-col>
      </v-row>
    </v-alert>


    <v-dialog v-model="alertDialog" max-width="610px">
      <v-card class="elevation-10">
        <v-card-title
            class="px-6 py-4 white"
            style="font-size: 16px; font-weight: 400"
        >
          <div class="font-weight-bold">⚠️ Terdapat data yang kosong pada beberapa gerbang tol.</div>
          <div class="text-body-2" style="font-family: 'Lexend', sans-serif !important;">Silakan hubungi pihak MIY atau
            upload data ATT6 CTP untuk melengkapi data. Berikut data gerbang belum tersedia :
          </div>
          <br>
          <div>
            <ul>
              <template v-for="(item) in this.checkedCtp.data.value">
                <li class="text-body-2" :key="item.gate_id" style="font-family: 'Lexend', sans-serif !important;">{{
                    item.name
                  }}: {{ item.error_text }}
                </li>
              </template>
            </ul>
          </div>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import mockFile from "@/mock/mockFile.js";

export default {
  props: {
    start_date: {
      type: String,
      required: true,
    },
    end_date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkedCtp: null,
      alertCheckedCtp: true,
      alertDialog: false,
    };
  },
  watch: {
    start_date: {
      handler(){
        this.fetchCtpChecked()
      }
    },
    end_date: {
      handler(){
        this.fetchCtpChecked()
      }
    }
  },
  computed: {
    fetchParams() {
      return {
        from: this.start_date,
        to: this.end_date,
        gates: this.formattedGate,
        graph_type: this.selectedPeriod,
      };
    },
  },
  methods: {
    async fetchCtpChecked() {
      try {
        const response = await this.$axios.get("check-ctp-att6", {
          params: this.fetchParams,
        });
        this.checkedCtp = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.fetchCtpChecked();
  }
};
</script>
