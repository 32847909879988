<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import DateFilterComponent from "@/components/LayananTransaksi/DateFilterComponent.vue";
import ReportSummaryComponent from "@/components/LayananTransaksi/VLL/ReportSummaryComponent.vue";
import RevenueGraphComponent from "@/components/LayananTransaksi/VLL/RevenueGraphComponent.vue";
import TableStyleComponentTotal from "@/components/LayananTransaksi/VLL/TableStyleComponentTotal.vue";
import TableStyleComponentDetail from "@/components/LayananTransaksi/VLL/TableStyleComponentDetail.vue";
import WarningUploadCTP from "@/components/LayananTransaksi/Alert/WarningUploadCTP.vue";

export default {
  components: {
    WarningUploadCTP,
    LoadingComponent,
    DateFilterComponent,
    ReportSummaryComponent,
    RevenueGraphComponent,
    TableStyleComponentTotal,
    TableStyleComponentDetail,
  },
  data() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
    return {
      loading: false,
      start_date: firstDayOfMonth.toISOString().split("T")[0],
      end_date: today.toISOString().split("T")[0],
      formatted_date_range: "",
      selectedPeriod: "daily",
      selectedGate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 18],
      selectedGolongan: ["1", "2", "3", "4", "5"],
      reportData: {
        summaryData: {},
        chartData: [],
        detailData: [],
      },
    };
  },
  computed: {
    formattedGate() {
      return this.selectedGate.join(",");
    },
    formattedGolongan() {
      return this.selectedGolongan.join(",");
    },
    fetchParamsSummary() {
      return {
        from: this.start_date,
        to: this.end_date,
      };
    },
    fetchParamsChart() {
      return {
        from: this.start_date,
        to: this.end_date,
        period: this.selectedPeriod,
        gate_ids: this.formattedGate,
        // group_ids: ""
      };
    },
    fetchParamsDetail() {
      return {
        from: this.start_date,
        to: this.end_date,
        gate_ids: this.formattedGate,
      };
    },
  },
  watch: {
    start_date() {
      this.fetchAllData();
    },
    end_date() {
      this.fetchAllData();
    },
    selectedPeriod() {
      this.fetchDataChart();
    },
    selectedGate() {
      this.fetchDataChart();
      this.fetchDataDetail();
    },
  },
  methods: {
    updateStartDate(newDate) {
      if (this.start_date !== newDate) {
        this.start_date = newDate;
      }
    },
    updateEndDate(newDate) {
      if (this.end_date !== newDate) {
        this.end_date = newDate;
      }
    },
    updateFormattedDateRange(newRange) {
      this.formatted_date_range = newRange;
    },
    updatePeriod(newPeriod) {
      if (this.selectedPeriod !== newPeriod) {
        this.selectedPeriod = newPeriod;
      }
    },
    updateGate(newGate) {
      if (JSON.stringify(this.selectedGate) !== JSON.stringify(newGate)) {
        this.selectedGate = newGate;
      }
    },
    updateGolongan(newGate) {
      if (JSON.stringify(this.selectedGolongan) !== JSON.stringify(newGate)) {
        this.selectedGolongan = newGate;
      }
    },
    debouncedFetchData(callback) {
      clearTimeout(this.fetchDebounceTimeout);
      this.fetchDebounceTimeout = setTimeout(() => {
        callback();
      }, 1500);
    },
    async fetchDataSummary() {
      try {
        const response = await this.$axios.get("vll-summaries", {
          params: this.fetchParamsSummary,
        });
        this.reportData.summaryData = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchDataChart() {
      try {
        const response = await this.$axios.get("vll-charts", {
          params: this.fetchParamsChart,
        });
        this.reportData.chartData = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchDataDetail() {
      try {
        const response = await this.$axios.get("vll-details", {
          params: this.fetchParamsDetail,
        });
        this.reportData.detailData = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchAllData() {
      this.loading = true
      await this.fetchDataSummary();
      await this.fetchDataChart();
      await this.fetchDataDetail();
      this.loading = false
    },
  },
  mounted() {
    this.fetchAllData();
  },
};
</script>

<template>
  <div class="pa-0 ma-0">
    <!-- Loading state -->
    <v-row
        v-if="loading"
        justify="center"
        align="center"
        style="height: 100vh"
    >
      <LoadingComponent/>
    </v-row>

    <!-- Content -->
    <div :class="loading ? 'none': ''">
      <div class="pb-3 pt-4 px-10">
        <v-row no-gutters>
          <v-col align="left" class="my-auto pe-5"
                 cols="12"
                 xs="12"
                 sm="12"
                 md="7">

            <WarningUploadCTP :start_date="this.start_date" :end_date="this.end_date"/>
          </v-col>
          <v-col align="right" class="my-auto"
                 cols="12"
                 xs="12"
                 sm="12"
                 md="5">
            <v-sheet>
              <v-row no-gutters>
                <v-col align="left" class="my-auto" cols="2">
                  <v-sheet>
                    <span
                        class="text--black text-uppercase"
                        style="font-size: 12px; font-weight: 400; color: #bbbbbb"
                    >Filter</span
                    >
                    <h6
                        class="text--black"
                        style="font-size: 14px; font-weight: 400"
                    >
                      Pilih Tanggal
                    </h6>
                  </v-sheet>
                </v-col>
                <v-col align="right" class="my-auto" cols="10">
                  <v-sheet>
                    <DateFilterComponent
                        @update:startDate="updateStartDate"
                        @update:endDate="updateEndDate"
                        @update:formattedDateRange="updateFormattedDateRange"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <ReportSummaryComponent v-if="reportData && reportData?.summaryData" :data="reportData?.summaryData"/>
      <RevenueGraphComponent
          :data="reportData.chartData"
          @update:selectedPeriod="updatePeriod"
          @update:selectedGate="updateGate"
          @update:selectedGolongan="updateGolongan"
      />
      <div class="pb-3 pt-4 px-10">
        <!-- Detail Section -->
        <div class="mb-5 rounded-xl py-4 px-6 text-center bordered">
          <h3 style="font-size: 18px; font-weight: 500" class="text-uppercase">
            Detail
          </h3>
        </div>
        <div class="py-5">
          <v-row class="mb-4">
            <v-col cols="9" class="my-auto">
              <h3 class="text--black" style="font-size: 20px; font-weight: 500">
                {{ formatted_date_range }}
              </h3>
            </v-col>
          </v-row>
          <TableStyleComponentTotal
              v-if="reportData && reportData.detailData[0]?.total_all_tvl"
              :data="reportData.detailData[0].total_all_tvl"
          />
          <div v-if="reportData && reportData.detailData[0]?.detail_per_gates">
            <template
                v-for="(item, index) in reportData.detailData[0].detail_per_gates"
            >
              <div :key="index">
                <TableStyleComponentDetail :data="item"/>
              </div>
            </template>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.bordered {
  background-color: #dae7ff;
  border-left: 4px solid #497bdd;
  border-right: 4px solid #497bdd;
}
</style>
